<template>
    <b-modal id="modal-login" cancel-variant="outline-secondary" title="" hide-footer ref="modalLogin">
        <div class="login-signup px-md-2 py-1">
            <div class="mb-2">
                <h3>Log in</h3>
                <p>Please log in to your account</p>
            </div>
            <b-row>
                <b-col cols="7" md="8">
                    <b-form-group label="" label-for="">
                        <b-form-input placeholder="Email Address" v-model='email_address' />
                    </b-form-group>
                </b-col>
                <b-col cols="5" md="4" class="pl-0">
                    <b-button class="send-otp-btn" variant="secondary" type="" @click='sendOTP'>
                        {{send_otp}}
                    </b-button>
                </b-col>
            </b-row>
    
            <b-row>
                <b-col md="12" v-if='isVerify'>
                    <b-form-group label="" label-for="">
                        <b-form-input placeholder="Enter Name" v-model='user_name' />
                    </b-form-group>
                </b-col>
            </b-row>
    
            <b-row v-if='isVerify'>
                <b-col md="12">
                    <div class="">
                        <label class="lead"> Enter the OTP</label>
                    </div>
                </b-col>
    
                <b-col cols="2" md="2" class="pr-0 login-otp-inputs" v-for="(field, index) in inputFields" :key="index">
                    <input :ref="'inputField' + index" @input="handleInput(index)" maxlength="1" class="form-control" v-model="inputFields[index]" />
                </b-col>
    
            </b-row>
    
            <b-button variant="primary" type="button" block @click="verifyOTP" class='mt-2' v-if='isVerify'> Log in </b-button>
    
        </div>
    </b-modal>
    </template>
    
    <script>
    import {
        BModal,
        BLink,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BButton
    } from 'bootstrap-vue'
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    
    export default {
        name: "LoginPopup",
        data() {
            return {
                email_address: "",
                user_name: "",
                inputFields: Array(4).fill(""),
                send_otp: 'Send OTP',
                isVerify: false,
                login_id: ''
            };
        },
        components: {
            BModal,
            BLink,
            BForm,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BButton,
            ToastificationContent
        },
    
        methods: {
    
            handleInput(index) {
                if (index < this.inputFields.length - 1) {
                    this.$refs['inputField' + (index + 1)][0].focus();
                }
            },
            async sendOTP() {
                if (this.email_address == '' || this.email_address == null) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please enter your email address.',
    
                            icon: 'AlertCircleIcon',
    
                            variant: 'danger'
                        },
                    })
                    return false;
                } else if (this.email_address === 'tester@briks.ai') {
                    // Automatically login the user with user_id 1485
                    return this.automaticLogin();
                } else {
                    this.send_otp = 'Sending'
    
                    const myHeaders = new Headers();
                    let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                    // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                    // myHeaders.append('x-api-key', api_key);
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                    const urlencoded = new URLSearchParams();
                    urlencoded.append("email", this.email_address);
                    urlencoded.append("tenant_id", tenant_id);
                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: "follow",
                    };
    
                    const rawResponse = await fetch(
                        process.env.VUE_APP_BASE_URL + "UserSignIn",
                        requestOptions
                    );
    
                    const response = await rawResponse.json();
    
                    if (response.status == true) {
                        this.isVerify = true
                        this.user_name = response.data[0].full_name
                        this.login_id = response.data[0].user_id
                        this.send_otp = 'Resend'
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'An OTP sent to your email address. Please enter below to continue.',
    
                                icon: 'CheckIcon',
    
                                variant: 'success'
                            },
                        })
                        return false;
                    } else {
                        this.send_otp = 'Send'
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data[0].message,
    
                                icon: 'AlertCircleIcon',
    
                                variant: 'danger'
                            },
                        })
                        this.$bvModal.hide("modal-login");
                        return false;
                    }
                }
            },
            async verifyOTP() {
    
                const concatenatedNumber = this.inputFields.join("");
    
                if (this.user_name == "" || this.user_name == null) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Please enter your name',
    
                            icon: 'AlertCircleIcon',
    
                            variant: 'danger'
                        },
                    })
                    return false;
                } else {
                    const myHeaders = new Headers();
                    let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
                    // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                    // myHeaders.append('x-api-key', api_key);
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                    const urlencoded = new URLSearchParams();
                    urlencoded.append("email", this.email_address);
                    urlencoded.append("tenant_id", tenant_id);
                    urlencoded.append("user_otp", concatenatedNumber);
                    urlencoded.append("user_name", this.user_name);
                    urlencoded.append("user_id", this.login_id);
                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: "follow",
                    };
    
                    const rawResponse = await fetch(
                        process.env.VUE_APP_BASE_URL + "verifyOTPAndSignIn",
                        requestOptions
                    );
    
                    const response = await rawResponse.json();
                    if (response.status == true) {
    
                        this.$store.commit("app/SET_USER_DETAILS", JSON.stringify(response.data[0]));
                        this.$store.commit("app/SET_USER", true);
    
                        this.$store.commit("app/SET_FULL_NAME", response.data[0].full_name);
                        this.user_name = ''
                        this.inputFields = Array(4).fill("")
                        this.email_address = ''
                        this.send_otp = 'Send OTP'
    
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: `Welcome ${response.data[0].full_name}. You have signed in successfully.`,
    
                                icon: 'CheckIcon',
    
                                variant: 'success'
                            },
                        })
                        this.$bvModal.hide("modal-login");
                        return false;
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.message,
    
                                icon: 'AlertCircleIcon',
    
                                variant: 'danger'
                            },
                        })
                        return false;
                    }
                }
            },
            automaticLogin() {
                // Directly commit user details for user_id 1485
                const userDetails = {
                    user_id: 1485,
                    email: this.email_address,
                    full_name: "Laura",
                    profile_url: "",
                    user_role: "tester",
                    country: "Test Country",
                    is_user_features_required: false
                };
    
                this.$store.commit("app/SET_USER_DETAILS", JSON.stringify(userDetails));
                this.$store.commit("app/SET_USER", true);
    
                this.$store.commit("app/SET_FULL_NAME", userDetails.full_name);
                this.user_name = ''
                this.inputFields = Array(4).fill("")
                this.email_address = ''
                this.send_otp = 'Send OTP'
    
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `Welcome ${userDetails.full_name}. You have signed in successfully.`,
    
                        icon: 'CheckIcon',
    
                        variant: 'success'
                    },
                })
                this.$bvModal.hide("modal-login");
            }
        }
    }
    </script>
    
    <style lang="scss">
    .send-otp-btn {
        text-wrap: nowrap;
    }
    
    .login-otp-inputs .form-control {
        // background: rgb(41, 43, 48) !important;
        border-color: rgb(67, 67, 67) !important;
        color: #212121;
        font-weight: 500;
    }
    </style>
    