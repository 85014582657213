<template>
<div class="">
    <!-- Footer -->
<!-- <div class="page-footer bg-light">
  <b-container>
    <b-row>
      <b-col lg="4" class="footer-about mb-2 mb-lg-0">
        <img src="@/assets/images/logo/logo.png" alt="cloud360s">
        <p>Donut jujubes I love topping I love sweet. Jujubes I love brownie gummi bears I love donut sweet chocolate. Tart chocolate marshmallow.Tart carrot cake muffin.</p>
        <p>Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies. Cupcake cake I love cake danish carrot cake.</p>
      </b-col>

      <b-col lg="2" class="quick-links mb-2 mb-lg-0">
        <h4>Quick Links</h4>
        <ul class="list-unstyled">
          <li class=""><b-link href="#">Home</b-link></li>
          <li><b-link href="#">About us</b-link></li>
          <li><b-link href="#">Events</b-link></li>
          <li><b-link href="#">Products</b-link></li>
          <li><b-link href="#">Contact us</b-link></li>
          <li><b-link href="#">Privacy policy</b-link></li>
          <li><b-link href="#">Sitemap</b-link></li>
        </ul>
      </b-col>

      <b-col lg="2" class="quick-links">
        <h4>Quick Links</h4>
        <ul class="list-unstyled">
          <li class=""><b-link href="#">Home</b-link></li>
          <li><b-link href="#">About us</b-link></li>
          <li><b-link href="#">Events</b-link></li>
          <li><b-link href="#">Products</b-link></li>
          <li><b-link href="#">Contact us</b-link></li>
          <li><b-link href="#">Privacy policy</b-link></li>
          <li><b-link href="#">Sitemap</b-link></li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</div> -->
<footer class="copyright-footer footer">
  <b-container>
    <b-row>
      <b-col lg="6" class="">
        <span>2024 © <span>{{companyName}}</span>. All Rights Reserved.</span>
      </b-col>

      <b-col lg="6" class="text-right">
        <span>Powered By <a href="https://briks.ai" target="_blank">BRIKS.AI</a></span>
      </b-col>
    </b-row>
  </b-container>
  </footer>
<!--End Footer -->

</div>
</template>

<script>
import { BCard, BCardText, BButton, BContainer, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BBadge, BLink,} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BBadge,
    BLink,
  },
  computed:{
    companyName () {
      return  this.$store.state.app.companyName
    }

  }
}
</script>

<style scoped>
body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
footer.footer {
  padding:24px 0;
}
</style>

<style>

</style>

<style lang="scss" scoped>

</style>

