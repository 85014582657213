<template>
<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
        <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
                {{full_name}}
            </p>
            <!--<span class="user-status">{{ user_role }}</span>-->
        </div>
        <b-avatar size="40" :text="avatarText(full_name)" :src="profileImageUrl" variant="light-primary" badge class="badge-minimal avatar-user" badge-variant="success">
            <!-- <feather-icon
          v-if="!profileImageUrl"
          icon="UserIcon"
          size="22"
        /> -->
        </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'profile-view', query: { id: true }}" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item href="/memberships" link-class="d-flex align-items-center">
    <feather-icon size="16" icon="UserPlusIcon" class="mr-50" />
    <span>Plans</span>  
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
    </b-dropdown-item>
</b-nav-item-dropdown>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-item
      :to="{ name: 'Memberships' }"
      link-class="d-flex align-items-center d-block d-md-none d-lg-none"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Memberships</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Billing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->

</template>

<script>
import axios from 'axios'
import {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue'
import {
    initialAbility
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import {
    avatarText
} from '@core/utils/filter'


export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    data() {
        return {
            avatarText,

            userData: [],
            ipAddress: '',
            user_id: ''
        }
    },

    setup() {
        return {
            avatarText,
        }
    },

   
    computed: {
        profileImageUrl() {
            return this.$store.state.app.profileImage
        },
        full_name() {
            return this.$store.state.app.fullName
        },
        user_role() {
            return this.$store.state.app.userRole
        }
    },
    methods: {

      
        async logout() {
            // Remove userData from localStorage
            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
            // myHeaders.append('x-api-key', api_key);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            urlencoded.append("user_type", 'public_user');
            urlencoded.append("ip_address", this.ipAddress);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "master/updateLogoutStatus",

                requestOptions
            );
            const response = await rawResponse.json();
            console.log(response)

            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            this.$store.commit("app/SET_REMOVE_ITEMS");

            this.$store.commit("app/SET_USER", false);

            this.$router.push('/index')

            // setTimeout(() => location.reload(), 2000);

        },
    },
}
</script>
